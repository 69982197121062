import React, { useEffect, useState } from "react";
import { useNavigate, Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useForm } from "../../hooks";
import { login } from "../../redux/actions/auth";
import { FormInputField } from "../../components";
import { Logo } from "../../components/Icons";
import CommonButton from "../../components/Buttons/CommonButton";
import SmallButton from "../../components/Buttons/SmallButton";
import Footer from "../../components/Footer";
import Slider from "../../components/Login/Slider";
import { setPageTitle } from "../../helper/metaDataHelper";
import PasswordToggleIcon from "../../components/Common/PasswordToggleIcon";
import LoginOTPModal from "../../components/Modals/LoginOTPModal";
import LoadingSpinner from "../../components/Icons/LoadingSpinner";
import { getOwnCompany } from "../../redux/actions/lookups/getOwnCompany";

const Signin = () => {
  const emailInput = useForm("", "email");
  const passwordInput = useForm("");
  const [isButtonDisabled, setIsButtonDisabled] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { user } = useSelector((state) => state.user);
  const [error, setError] = useState("");
  const company = useSelector((state) => state.ownCompany.company);
  const short_name = process.env.REACT_APP_COMPANY_SHORT_NAME;
  
  const { isLoading } = useSelector((state) => state.common);
  const [LoginOTPModalOpen, setLoginOTPModalOpen] = useState(false);
  const [otpModalLoading, setOtpModalLoading] = useState(false);
  const [showPassword, setShowPassword] = useState({
    password: false,
  });
  
  const togglePasswordVisibility = (field) => {
    setShowPassword({
      ...showPassword,
      [field]: !showPassword[field],
    });
  };

  useEffect(() => {
    if (user) {
      navigate("/");
    }
    setPageTitle("Client Login");
  }, [user, navigate, dispatch]);

  const handleLogin = async (e) => {
    if (e) e.preventDefault();
    setError("");
    const checkEmail = emailInput.validate();
    if (checkEmail) {
      return;
    }

    const userData = {
      email: emailInput.value,
      password: passwordInput.value,
    };
    const checkMFA = process.env.REACT_APP_MFA === "true" ? true : false;
    const res = await dispatch(login(userData));

    if (res.success === false) {
      setError(res.message);
    } else if (res.status === "RateLimited") {
      setIsButtonDisabled(true);
      const duration = 60;
      setError(`Too many attempts. Please try again in ${duration} seconds.`);
      startCountdown(duration);
    } else if (checkMFA) {
      setLoginOTPModalOpen(true);
    } else {
      dispatch(getOwnCompany())
      navigate("/");
    }
  };

  const startCountdown = (duration) => {
    let timeLeft = duration;
    const timerInterval = setInterval(() => {
      timeLeft -= 1;

      setError(
        (prevError) =>
          `${prevError.split(".")[0]}. Please try again in ${timeLeft} seconds`
      );

      if (timeLeft <= 0) {
        clearInterval(timerInterval);
        setError("");
        setIsButtonDisabled(false);
      }
    }, 1000);
  };

  return (
    <>
      <div className="max-w-[1024px] mx-auto px-4 py-8 min-h-dvh flex-1 flex flex-col justify-center">
        <div className="flex flex-col sm:flex-row justify-center gap-3'">
          {/* First sections */}
          <div className="md:w-[572px] w-full sm:flex-grow mb-6 sm:mb-0 space-y-4">
            <div className="flex flex-col items-center justify-between max-w-[384px] h-[684px]">
              <Logo />

              <div className="flex flex-col items-center justify-center w-full">
                <p className="text-body-text text-[42px] font-medium tracking-tight">
                  Welcome back,
                </p>
                <div className="justify-center items-center gap-1 inline-flex">
                  <p className="text-paragraph-text text-sm font-normal tracking-tight">
                    New to {company &&  company.short_name ? company.short_name : short_name }?
                  </p>
                  <Link
                    to={"/signup"}
                    className="text-primary-item text-sm underline font-medium tracking-tight"
                  >
                    Sign up
                  </Link>
                </div>
                <form
                  onSubmit={handleLogin}
                  className="w-full pt-[52px] space-y-4"
                >
                  <FormInputField
                    type="text"
                    name="email"
                    id="email"
                    label="Email"
                    placeholder="Enter your email"
                    {...emailInput}
                    required
                  />
                  <FormInputField
                    id={"password"}
                    label={"Password"}
                    type={showPassword.password ? "text" : "password"}
                    placeholder={"Enter your password"}
                    {...passwordInput}
                    icon={
                      <PasswordToggleIcon
                        isVisible={showPassword.password}
                        onToggle={() => togglePasswordVisibility("password")}
                      />
                    }
                  />
                  {error && LoginOTPModalOpen === false ? (
                    <p className="text-highlighter-red text-xs font-normal tracking-tight">
                      {error}
                    </p>
                  ) : (
                    ""
                  )}

                  <CommonButton
                    type="submit"
                    disabled={
                      !emailInput.value ||
                      !passwordInput.value ||
                      isLoading ||
                      isButtonDisabled
                    }
                    onClick={handleLogin}
                  >
                    <div className="flex items-center justify-center">
                      Log in
                      {isLoading && <LoadingSpinner />}
                    </div>
                  </CommonButton>
                  <div className="flex items-center justify-center pt-4">
                    <SmallButton
                      href={"/forgot-password"}
                      text={"Forgot password?"}
                    />
                  </div>
                </form>
              </div>

              <Footer />
            </div>
          </div>
          {/* Second sections */}

          <Slider />
        </div>
      </div>

      <LoginOTPModal
        isLoading={otpModalLoading}
        setisLoading={setOtpModalLoading}
        isModalOpen={LoginOTPModalOpen}
        setIsModalOpen={setLoginOTPModalOpen}
        email={emailInput.value}
        reSendOTP={handleLogin}
        isButtonDisabled={isButtonDisabled}
        setIsButtonDisabled={setIsButtonDisabled}
        Error={error}
        setError={setError}
      />
    </>
  );
};

export default Signin;
