import { Tab } from "@headlessui/react";
import { Link, useLocation } from "react-router-dom";
import { useModules } from "../../hooks";
import { useSelector } from "react-redux";

function classNames(...classes) {
  return classes.filter(Boolean).join(" ");
}

const HeaderTabs = () => {
  const location = useLocation();
  const { user } = useSelector((state) => state.user);

  const tabs = [
    { label: "Accounts", path: "/" },
    { label: "Cards", path: "/card" },
    { label: "Crypto", path: "/crypto" },
  ];

  const isCryptoEnabled = useModules("Crypto");
  const isCardEnabled = useModules("VistaMoney");

  const availableTabs = tabs.filter((tab) => {
    if (user?.is_basic_client) {
      return tab.label === "Accounts";
    }

    if (tab.label === "Crypto") {
      return isCryptoEnabled;
    }

    if (tab.label === "Cards") {
      return isCardEnabled;
    }

    return true;
  });

  return (
    <Tab.Group>
      <Tab.List className="flex space-x-2">
        {availableTabs.map((tab, index) => (
          <SingleTab
            key={index}
            label={tab.label}
            to={tab.path}
            isSelected={tab.path === location.pathname}
          />
        ))}
      </Tab.List>
    </Tab.Group>
  );
};

const SingleTab = ({ label, to, isSelected }) => (
  <Link to={to}>
    <Tab
      className={classNames(
        "px-4 py-2 rounded-3xl text-body-text text-sm font-medium leading-tight tracking-tight ease-linear transition duration-1000",
        isSelected
          ? "bg-primarycard-gradient dark:bg-tabs-gradient border border-input-bg shadow-box-shadow transform transition-transform"
          : "text-paragraph-text"
      )}
    >
      {label}
    </Tab>
  </Link>
);

export default HeaderTabs;
