import React, { useEffect, useState } from "react";
import { SearchIcons } from "../../../Icons";
import AddBeneficiaryModal from "../AddBeneficiaryModal";
import CardButton from "../../../Card/CardButton";
import Modal from "../../../Common/Modal";
import { useDispatch } from "react-redux";
import { listBeneficiary } from "../../../../redux/actions/TRrequest/Beneficiary";
import BeneficiarySelectButton from "../../../Buttons/BeneficiarySelectButton";
import BeneficiarySkeleton from "../../../Skeleton/BeneficiarySkeleton";
import EmptyBeneficiaryIcon from "../../../Icons/EmptyBeneficiaryIcon";

const SelectBeneficiaryModal = ({
  beneficiaryAccount,
  setBeneficiaryAccount,
  children,
}) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [addBeneficiaryModalOpen, setaddBeneficiaryModalOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    const GetBeneficiaries = async () => {
      try {
        setIsLoading(true);
        const res = await dispatch(listBeneficiary());
        setBeneficiaries(res);
      } catch (error) {
        console.error("Error in Manage Beneficiary useEffect:", error);
      } finally {
        setIsLoading(false);
      }
    };
    GetBeneficiaries();
  }, [addBeneficiaryModalOpen, dispatch]);

  const filteredBeneficiaries = beneficiaries.filter(
    (account) =>
      account.name.toLowerCase().includes(searchQuery.toLowerCase()) ||
      account.account_number
        .toLowerCase()
        .includes(searchQuery.toLowerCase()) ||
      account.swift_code.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <>
      <div onClick={() => setIsModalOpen(true)}>{children}</div>
      <Modal
        isOpen={isModalOpen}
        handleOnCloseModal={() => setIsModalOpen(false)}
        header={true}
        headerTitle={"Select Beneficiary"}
        className="bg-popup-bg w-[432px] max-h-[613px] overflow-scroll hide-scrollbar shadow-xl rounded-3xl"
      >
        <div className="flex items-center justify-center px-6">
          <div className="w-[572px] relative">
            {/* Search input */}
            <div className="px-3 py-1 bg-secondary-icon-bg dark:bg-[#35322A] rounded-full flex justify-start items-center">
              <SearchIcons className="w-6 h-6" />
              <input
                onChange={(e) => setSearchQuery(e.target.value)}
                value={searchQuery}
                placeholder="Search"
                className="w-full text-paragraph-text text-sm font-normal bg-transparent border-none focus:ring-0 leading-tight tracking-tight"
              />
            </div>
            <AddBeneficiaryModal
              addBeneficiaryModalOpen={addBeneficiaryModalOpen}
              setaddBeneficiaryModalOpen={setaddBeneficiaryModalOpen}
            >
              <CardButton
                title={"Add new beneficiary"}
                subtitle={"Add new beneficiary to send money"}
              />
            </AddBeneficiaryModal>

            <hr className="my-3 border border-divider-border" />

            {isLoading ? (
              <div className="pb-4">
                <BeneficiarySkeleton />
              </div>
            ) : beneficiaries.length === 0 ? (
              <div className="flex flex-col items-center justify-center space-y-6 py-6">
                <EmptyBeneficiaryIcon className="w-40 h-40" />
                <span className="font-medium text-body-text text-xl px-6 text-center">
                  Add new beneficiaries
                </span>
                <span className="font-medium text-paragraph-text text-sm px-6 text-center">
                  You haven`t added any beneficiaries yet, but don`t miss out on
                  the seamless transfers. let`s get you set up!
                </span>
              </div>
            ) : filteredBeneficiaries.length ? (
              filteredBeneficiaries.map((beneficiary) => (
                <div
                  key={beneficiary.id}
                  onClick={() => {
                    setBeneficiaryAccount(beneficiary);
                    setIsModalOpen(false);
                  }}
                >
                  <BeneficiarySelectButton
                    beneficiaryName={beneficiary.name}
                    accountNumber={beneficiary.account_number}
                    swiftCode={beneficiary.swift_code}
                    select={beneficiaryAccount}
                    beneficiaryId={beneficiary.id}
                  />
                </div>
              ))
            ) : (
              <div className="flex items-center justify-center py-5 text-body-text font-medium">
                <p>No results match your search</p>
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SelectBeneficiaryModal;
